import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EbayAuthenService {
  _code = 'https%3A%2F%2Fapi.ebay.com%2Foauth%2Fapi_scope';
  private clientId = 'tringuye-test-PRD-d4f8d5ffa-b06b2cf1';
  private clientSecret = 'PRD-4f8d5ffaa2ef-f10a-4081-914f-c1a4';
  private redirectUri = 'http://localhost:4200';
  private authUrl = 'https://auth.ebay.com/oauth2/authorize';
  private tokenUrl = '/otherapi/identity/v1/oauth2/token';
  constructor(private http : HttpClient) { }
  // generateRandomState(): string {
  //   return Math.random().toString(36).substring(2);
  // }
  // getAuthen():string{
  //   const scopes = [
  //     'https://api.ebay.com/oauth/api_scope',
      
  //   ];
  //   const state = this.generateRandomState();
  //   console.log(state);
  //   const params = new HttpParams()
  //     .set('client_id', this.clientId)
  //     .set('redirect_uri', this.redirectUri)
  //     .set('scope', 'https://api.ebay.com/oauth/api_scope');
  //   return `${this.authUrl}?${params.toString()}`;
  // }

  getAccessToken(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Basic ${btoa(`${this.clientId}:${this.clientSecret}`)}`
    });
    console.log(`Basic ${btoa(`${this.clientId}:${this.clientSecret}`)}`);
    const body = new HttpParams()
      .set('grant_type', 'client_credentials')
      .set('code', this._code);

    return this.http.post(this.tokenUrl, body.toString(), { headers });
  }
}
