import {
  HttpClient,
  HttpClientModule,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as convert from 'xml-js';
import { EbayAuthenService } from './ebay-authen.service';
@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private browseApiUrl =
    '/otherapi/buy/browse/v1/item/get_item_by_legacy_id?legacy_item_id=';
  private clientId = 'NguynTr-test-PRD-4ee7dbabf-f9b7c951';
  private clientSecret = 'PRD-ee7dbabffa02-df59-4d9c-b927-b225';
  // getDetailProduct(id: string, token : string) : any{
  //   const headers = new HttpHeaders({
  //     'Authorization': `Bearer ${token}`,
  //     'Content-Type': 'application/json'
  //   });
  //   return this.http.get(`${this.browseApiUrl}${id}`, { headers });
  // }

  //  private urlApi1 = 'http://localhost:8080/https://svcs.ebay.com/services/search/FindingService/v1?OPERATION-NAME=findItemsAdvanced&SERVICE-VERSION=1.0.0&SECURITY-APPNAME=NguynTr-test-PRD-4ee7dbabf-f9b7c951&REST-PAYLOAD&paginationInput.entriesPerPage=100&RESPONSE-DATA-FORMAT=XML&paginationInput.pageNumber=1&sortOrder=StartTimeNewest&categoryId=58058&keywords=keyboard';
  //  private  appId  =`NguynTr-test-PRD-4ee7dbabf-f9b7c951`; old
  private appId = `tringuye-test-PRD-d4f8d5ffa-b06b2cf1`;
  private apiUrl = 'https://svcs.ebay.com/services/search/FindingService/v1';
  private apiPHPUrl = '/products.php';
  constructor(
    private http: HttpClient,
    private ebayAuthenService: EbayAuthenService
  ) {}
  getProduct(product: string): Observable<any> {
    const urlEbay = `/api/services/search/FindingService/v1?OPERATION-NAME=findItemsAdvanced&SERVICE-VERSION=1.0.0&SECURITY-APPNAME=NguynTr-test-PRD-4ee7dbabf-f9b7c951&REST-PAYLOAD&paginationInput.entriesPerPage=20&RESPONSE-DATA-FORMAT=JSON&paginationInput.pageNumber=1&sortOrder=StartTimeNewest&categoryId=58058&keywords=${product}`;
    
    return this.http.get(urlEbay);
  }
  getProductWithPHP(product: string): Observable<any> {
     return this.http.get<any>(`${this.apiPHPUrl}?action=getItem&id=${product}`);

  }
  // getFollow(keywords: string[]): Observable<any>[] {
  //   const headers = new HttpHeaders({
  //     'X-EBAY-SOA-OPERATION-NAME': 'findItemsByKeywords',
  //     'X-EBAY-SOA-SERVICE-VERSION': '1.0.0',
  //     'X-EBAY-SOA-REQUEST-DATA-FORMAT': 'JSON',
  //     'X-EBAY-SOA-GLOBAL-ID': 'EBAY-US',
  //     'X-EBAY-SOA-SECURITY-APPNAME': this.appId
  //   });

  //   const requests = keywords.map(keyword => {
  //     const params = new HttpParams().set('keywords', keyword);
  //     return this.getProduct(keyword);
  //   });

  //   return requests;
  // }
}
