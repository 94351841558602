import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { ProductComponent } from './product/product.component';
import { ProductService } from './product.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { EbayAuthenService } from './ebay-authen.service';
import { LogUrlInterceptor } from './log-url.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    ProductComponent,
    
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonModule
      ],
  providers: [{provide: HTTP_INTERCEPTORS,useClass: LogUrlInterceptor, multi: true},EbayAuthenService],
  bootstrap: [AppComponent]
})
export class AppModule { }
