import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class LogUrlInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    console.log('Request URL:', 'From Tri Nguyen - 1112341nhtri@gmail.com' + request);
    return next.handle(request).pipe(
      tap(event => {
        // Log the URL after the proxy has replaced it (if needed)
        console.log('Processed Request URL:', 'To Ebay' + request);
      })
    );
  }
}
