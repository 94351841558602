import { Component, Inject, OnInit } from '@angular/core';
import { ProductService } from '../product.service';
import { CommonModule } from '@angular/common'; // Import CommonModule

import * as convert from 'xml-js';
import { Subscription } from 'rxjs/internal/Subscription';
import { interval } from 'rxjs/internal/observable/interval';
import { EbayAuthenService } from '../ebay-authen.service';
import { forkJoin,Observable } from 'rxjs';
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
})
export class ProductComponent implements OnInit {
  itemIds :string[]=['124080446459','116102072367'];
  subscription: Subscription;

   text :string;
   inks :any;
   heads : any;
  follows : any[]=[];
  constructor(private productService: ProductService, private ebayAuthenService :EbayAuthenService) {}
  ngOnInit(): void {
    this.getData();
    this.getFollow();
    this.subscription = interval(60000).subscribe(()=>{
      this.getData();     
    });
  }
  getData() {
    this.productService.getProductWithPHP('Ink').subscribe( rs=> {
     this.inks = rs.findItemsAdvancedResponse[0].searchResult[0].item;
    console.log('inks' + rs);
  });
  this.productService.getProductWithPHP('printhead').subscribe( rs=> {
     this.heads = rs.findItemsAdvancedResponse[0].searchResult[0].item;
  })
}
getFollow() {
  const requests: Observable<any>[] = this.itemIds.map(id => 
    this.productService.getProductWithPHP(id)
  );

  forkJoin(requests).subscribe(
    responses => {
      responses.forEach(rs =>{
        if(rs.findItemsAdvancedResponse[0].paginationOutput[0].totalEntries >0) {
            this.follows.push(rs.findItemsAdvancedResponse[0].searchResult[0].item[0]);
        }
      })
    },
    error => {
      console.error('Error:', error);
    }
  );
}
}
