<div class="container">
    <div class="row">
        <div class="col-md-4 mb-4 d-flex align-items-center justify-content-center bg-primary">
            <h4>Ink</h4>
        </div>
        <div class="col-md-4 mb-4 d-flex align-items-center justify-content-center bg-primary">
            <h4>Print head</h4>
        </div>
        <div class="col-md-4 mb-4 d-flex align-items-center justify-content-center bg-primary">
            <h4>Follow Products</h4>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 mb-4">
            <div *ngFor="let p of inks; let i = index">
                <div class="card h-100" [ngClass]="{'odd': i % 2 !== 0, 'even': i % 2 === 0}">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-4">
                                <a [href]="p.viewItemURL ">
                                    <img [src]="p.galleryURL " class="img-fluid">
                                </a>
                            </div>
                            <div class="col-8">
                                <a [href]="p.viewItemURL ">
                                    <h5 class="card-title">{{ p.title  }}</h5>
                                </a>
                                <p class="card-text price">{{ p.sellingStatus[0].currentPrice[0].__value__  }} USD</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 mb-4">
            <div *ngFor="let p of heads; let i = index">
                <div class="card h-100" [ngClass]="{'odd': i % 2 !== 0, 'even': i % 2 === 0}">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-4">
                                <a [href]="p.viewItemURL ">
                                    <img [src]="p.galleryURL " class="img-fluid">
                                </a>
                            </div>
                            <div class="col-8">
                                <a [href]="p.viewItemURL ">
                                    <h5 class="card-title">{{ p.title  }}</h5>
                                </a>
                                <p class="card-text price">{{ p.sellingStatus[0].currentPrice[0].__value__  }} USD</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 mb-4">
            <div *ngFor="let p of follows; let i = index">
                <div class="card h-100" [ngClass]="{'odd': i % 2 !== 0, 'even': i % 2 === 0}">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-4">
                                <a [href]="p.viewItemURL ">
                                    <img [src]="p.galleryURL " class="img-fluid">
                                </a>
                            </div>
                            <div class="col-8">
                                <a [href]="p.viewItemURL ">
                                    <h5 class="card-title">{{ p.title  }}</h5>
                                </a>
                                <p class="card-text price">{{ p.sellingStatus[0].currentPrice[0].__value__  }} USD</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
